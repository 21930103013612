import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';
import {Image} from "react-bootstrap";

ProjectItem.propTypes = {
    item: PropTypes.object,
};

function ProjectItem(props) {
    const {item} = props;

    return (
        <div className="img-box">
            <Link to={`/nft/` + item.token_id}>
                <Image src={item.image_preview_url} className="click-nft" alt="DinoCpp" />
            </Link>
            <div className="content">
                <Link to={`/nft/` + item.token_id}>{item.name}</Link>
            </div>
        </div>
    );
}

export default ProjectItem;