const dataFaq = [
    {
        id: 1,
        title: 'What are the NFTs?',
        content: 'DinoCpp is a unique and collectible NFT collection that runs on the Ethereum Blockchain. We are so excited to show what DinoCpp is really about. So GET READY because July will be the month of inspiration and innovation!',
        show: 'show'
    },
    {
        id: 2,
        title: 'How many DinoCPP are in the collection?',
        content: '2,584',
    },
    {
        id: 3,
        title: 'How can we buy your NFTs?',
        content: 'DinoCpp NFTs can be traded on all popular NFT trading platforms, with Opensea.io and Binance being the most popular in terms of volume. Alternative options are looksrare.org.',
    },
    {
        id: 4,
        title: 'What is the mint price?',
        content: '0.01 ETH',
    },
    {
        id: 5,
        title: 'Is there a rarity list?',
        content: 'All DinoCpp\'s are unique and important. However some traits are more rare than others. We will introduce you with the most valuable ones later in the project.',
    },
    {
        id: 6,
        title: 'What are the holder benefits?',
        content: 'Free tech courses in partnership with Embedded school. Staking of NFT in DinoCPP Bank and earn DCPP coins. Merch and Comics will be released. Metaverse & Gamefy integration, users will have access to DinoCPP Land VR Game.',
    },
]

export default dataFaq;