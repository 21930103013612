import HomeOne from "./HomeOne";
// import HomeTwo from "./HomeTwo";
// import HomeThree from "./HomeThree";
// import AboutOne from "./AboutOne";
import AboutTwo from "./AboutTwo";
// import ComingSoon from "./ComingSoon";
// import Community from "./Community";
import FAQ from "./FAQ";
import Page404 from "./404";
import RoadMapOne from "./RoadMapOne";
// import RoadMapTwo from "./RoadMapTwo";
import NftItem from "./NftItem";
import NftItemDetails from "./NftItemDetails";
// import Blog from "./Blog";
// import BlogDetails from "./BlogDetails";
// import Contact from "./Contact";
// import TeamDetail from "./TeamDetail";
import Terms from "./Terms";
import History from "./History";


const routes = [
  { path: '/', component: <HomeOne />},
  // { path: '/home-v2', component: <HomeTwo />},
  // { path: '/home-v3', component: <HomeThree />},
  // { path: '/about-v1', component: <AboutOne />},
  { path: '/about', component: <AboutTwo />},
  // { path: '/coming-soon', component: <ComingSoon />},
  // { path: '/community', component: <Community />},
  { path: '/faq', component: <FAQ />},
  { path: '/404', component: <Page404 />},
  { path: '/road-map', component: <RoadMapOne />},
  // { path: '/road-map-v2', component: <RoadMapTwo />},
  { path: '/nfts', component: <NftItem />},
  { path: '/nft/:id', component: <NftItemDetails />},
  // { path: '/blog', component: <Blog />},
  // { path: '/blog-details', component: <BlogDetails />},
  // { path: '/contact', component: <Contact />},
  { path: '/terms', component: <Terms />},
  { path: '/history', component: <History />},
  // { path: '/team-details', component: <TeamDetail />},

]

export default routes;