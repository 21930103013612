const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 2,
        name: 'History',
        links: '/history',
    },
    {
        id: 3,
        name: 'About',
        links: '/about',
    },
    {
        id: 4,
        name: 'Road Map',
        links: '/road-map',
    },
    {
        id: 5,
        name: 'FAQ',
        links: '/faq',
    },
    {
        id: 6,
        name: 'Nfts',
        links: '/nfts',
    },
]

export default menus;