import React from "react";
import PageTitle from "../components/pagetitle";

function Terms() {
    return (
        <div>
            <PageTitle title='Terms & Conditions' />

            <section className="tf-section faq bg-st2">
                <div className="container">
                    <div className="row">
                        <h6>Welcome to DinoCpp</h6>
                        <p>DinoCpp is an Ethereum blockchain-based generative project consisting of 2,584 unique DinoCpp avatar’s
                            displaying randomized traits. The DinoCpp team are existing business owners with a passion for
                            quality art and community building. These terms of service (“TOS”) are intended to provide clear,
                            transparent guidelines for responsible ownership and use of DinoCpp’s. Any questions can be sent
                            along to the Creative Team, via Twitter or Discord</p>
                        <h6>Ownership and Commercial Rights of DinoCpp NFT’s</h6>
                        <p>DinoCpp’s consists of 2,584 individual ERC-721 tokens (individually “DinoCpp”, collectively “DinoCpp” or “NFTs”) which include unique generative artwork hosted on Inter Planetary File System
                            (“IPFS”). By minting a DinoCpp NFT from our smart contract, purchasing it off the secondary market
                            (e.g. OpenSea), or otherwise acquiring the DinoCpp through any other legitimate means or method, you
                            obtain a worldwide, non-exclusive, non-transferable, royalty-free license to use, copy, and display
                            content for such NFT, solely for the following purposes: (a) for your own personal, non-commercial use;
                            (b) as part of a marketplace that permits the purchase and sale of NFTs, provided that the marketplace
                            cryptographically verifies each NFT’s owner’s rights to display the content for their NFT to ensure that
                            only the actual owner can display the content; or (c) as part of a third party website or application
                            that permits the inclusion, involvement, or participation of your NFT, provided that the
                            website/application cryptographically verifies each NFT’s owner’s rights to display the content for
                            their NFT to ensure that only the actual owner can display the content, and provided that the content is
                            no longer visible once the owner of the NFT leaves the website/application. This license only lasts as
                            long as you are the valid owner and holder of the NFT associated with the licensed content. If you sell
                            or transfer the NFT to another person, this license will transfer to such other owner or holder of the
                            NFT, and you will no longer have the benefits of such license. All rights not expressly granted are
                            reserved.</p>
                        <p>You agree that you may not, nor permit any third party to do or attempt to do any of the following
                            without our (or, as applicable, our licensors’) express prior written consent in each case: (a) modify
                            the content for your NFT in any way, including, without limitation, the shapes, designs, drawings,
                            attributes, or color schemes; (b) use the content for your NFT to advertise, market, or sell any third
                            party product or service; (c) use the content for your NFT in connection with images, videos, or other
                            forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could
                            reasonably be found to constitute hate speech or otherwise infringe upon the rights of others; (d) sell,
                            distribute for commercial gain (including, without limitation, giving away in the hopes of eventual
                            commercial gain), or otherwise commercialize merchandise that includes, contains, or consists of the
                            content for your NFT; (e) attempt to trademark, copyright, or otherwise acquire additional intellectual
                            property rights in or to the content for your NFT; or (f) otherwise utilize the content for your NFT for
                            your or any third party’s commercial benefit.</p>
                        <p>If the content associated with your NFT contains third party IP (e.g., licensed intellectual property
                            from any rights holder, such as music performance rights or publicity rights), you understand and agree
                            as follows: (a) you will not have the right to use such third party IP in any way except as incorporated
                            in the content for your NFT, and subject to the license and restrictions contained herein; (b) depending
                            on the nature of the license granted from the owner of the third party IP, we may need to (and reserve
                            every right to) pass through additional restrictions on your ability to use the content; and (c) to the
                            extent that we inform you of such additional restrictions, you will be responsible for complying with
                            all such restrictions from the date that you receive the notice, and that failure to do so will be
                            deemed a breach of the license contained herein.</p>
                        <p>You agree not to use, utilize, portray, advertise, distribute or otherwise market any DinoCpp in any
                            project or derivative work that involves hate speech, racism, pornography, or any other illegal or
                            unlawful content. Upon sale or transfer of the NFT, any ownership or commercial rights are immediately
                            transferred to the new holder. No refunds shall be issued to any holder upon a full and complete lawful
                            purchase of any NFT or DinoCpp. In the event that any holder purchases a DinoCpp through the secondary
                            market, such as OpenSea, the holder shall be held accountable and will be bound by the Terms of Service
                            which govern said secondary market platform.</p>
                        <h6>Limitations of Liability for Gas, Failed Transactions, Smart Contract Bugs</h6>
                        <p>Participants in minting DinoCpp NFTs agree to hold the project Creative Team and for any losses
                            incurred as a consequence of minting an NFT. These potential losses include any gas fees for failed
                            transactions, any excessive gas fees charged due to website or smart contract bugs, and any loss of any
                            NFT due to website or smart contract bugs.</p>
                        <h6>No Guarantees or Future Promises</h6>
                        <p>While DinoCpp has released a roadmap outlining future goals and plans for community development, we
                            cannot guarantee to accomplish every item outlined during the pre-launch planning phase as ideas and
                            projects evolve organically. You agree that your purchase of a DinoCpp from our initial launch of 2,584
                            NFTs is all you are guaranteed to receive with your initial purchase, whether through primary or
                            secondary channels. Any future benefits are ancillary to this purchase and not to be taken into
                            consideration with your initial purchase. You agree that you are not relying on any future commitments
                            by DinoCpp in using this site and participating in our NFT launch.</p>
                        <h6>No Guarantees of Value</h6>
                        <p>DinoCpp NFTs were created purely as collectibles, not as investment vehicles or substitutes for
                            cryptocurrency. We make absolutely no promise or guarantee that these NFTs will subsequently retain
                            monetary value in fiat, cash or cryptocurrency.</p>
                        <h6>Taxes</h6>
                        <p>Each holder is solely and entirely responsible for any and all Federal or State tax liabilities which may
                            arise, be imposed, or enforced as a result of minting or reselling DinoCpp</p>
                        <h6>Class Action Waiver, Jurisdiction and Choice of Law</h6>
                        <p>You agree to waive any class action status, and any legal dispute around the DinoCpp project which
                            you may choose to bring can only be done on an individual basis.</p>
                        <h6>Children</h6>
                        <p>The DinoCpp project is not targeted towards children. You agree that you are over the age of 18, or
                            above the legal age of your jurisdiction, whichever is greater. Any individual under the age of 18
                            participating in this project should only do so under parental supervision.</p>
                        <h6>Privacy Policy</h6>
                        <p>DinoCpp will not collect any cookies, IP addresses, or user data in connection with your use of the
                            website and app, with the exception of cookies related to browsing behavior for the Google analytics SEO
                            which helps us enhance your experience with our site and relay visitor traffic statistics to the Google
                            search engine. All project participants understand that the Ethereum blockchain network is a public
                            blockchain, and all transaction history initiated through the website will be made public. DinoCpp
                            has no influence on secondary marketplaces, such as OpenSea which have independent terms of service
                            agreed to by you, should you choose to utilize these sites. You also understand that DinoCpp may
                            work with third-party apps, such as Discord or Collab.Land, which collect and store user data. Should
                            DinoCpp be required to collect or store data for future activities such as merchandise launches or
                            transfer of any benefits such as airdrops, you will be required to agree to the collection of this data.
                            This provision may be subject to change, when deemed fit by the DinoCpp team.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Terms;