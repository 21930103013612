import React from "react";
import PageTitle from "../components/pagetitle";

import imgPost1 from "../assets/images/background/bg4.png";
import imgPost2 from "../assets/images/post/dino pc.jpg";
import imgPost3 from "../assets/images/post/dino student.png";
import imgPost4 from "../assets/images/post/business model.png";
import {Image} from "react-bootstrap";

function History() {
    return (
        <div className="post-details">
            <PageTitle title="History" />
            <section className="page-title">
                <div className="image image-history">
                    <Image src={imgPost1} alt="bg history" width="100%" />
                </div>
            </section>
            <section className="tf-section detail">
                <div className="container">
                    <div className="row">
                        <article className="article" data-aos="fade-in" data-aos-duration="800">
                            <div className="content-details">
                                <h6>[History]</h6>
                                <div className="image-history">
                                    <Image src={imgPost2} alt="dinocpp pc" thumbnail={true} width={210} />
                                </div>
                                <p className="st2">245 million years ago on earth planet, Tyrannosaurus and Megalosaurus were in a battle agains climate crisis, Ice period and falling of sollar system asteroids.
                                    In those circumstances and years of thinking how to win Ice period and asteroids they decided to go tech and build smart systems to solve their problems. That's how C++ programming
                                    language appeared. Dinos started coding C++, they knew that for their problems they need a "general-puropose" programming language so they made it procedural and also
                                    object oriented to have it flexible and powerfull. From this point things took off quickly, soon one Dino made browser and put his frind in a Game named it "Dinosaur Game" other dinos
                                    started Smart Contracts, Gamming and they started code everything.</p>
                                <p>Since then there is a joke on inthernet that C++ programming language is for dinosaurs and C++ developers are Dinosaurs.</p>
                            </div>
                            <div className="content-details">
                                <h6>[Nowaday]</h6>
                                <div className="image-history">
                                    <Image src={imgPost3} alt="dinocpp student" thumbnail={true} width={210} />
                                </div>
                                <p>Now in 2022 where E=mc2 and Gravity of the universe is not yet clearly defined we live some obsoleted system like banking, schooling, WEB2 and other systems.</p>
                                <p>In this Content Dinos came again to solve at least one problem  that could be improved. DinoCPP are here to improve online courses and learning mecanism of the future. DinoCPP has a vision
                                    of interactive IN METAVERSE university/school where users can interact virtually with other peoples, feel their touch and live real emotions from brand new learning method in VR model. </p>
                                <p>DinoCPP Mission is to bring metaverse university/school and improve actual tech courses websites. First course that will take place in Metaverse tech School will be C++ programming language.
                                    In partnership with <a href="https://imetatoken.org/" target="_blank" rel="noreferrer">imetatoken.org</a> this series of DinoCPP NFT will give you free access to participate to this courses as a student and also will give you permission to become a trainer or couch.</p>
                                <p>Let's turn the page for next generation learning model! </p>
                            </div>
                            <div className="content-details">
                                <h6>[Business Model]</h6>
                                <div className="image-history">
                                    <Image src={imgPost4} alt="dinocpp nft" width={410} />
                                </div>
                                <p>Now we'are working on a personal Crypto Coin that will be the main coin to buy Courses in Metaverse University/School, buy utilities and features hat we will define.</p>
                                <p>Access to Meva School will be done via NFT login that we will make in the future and NFT that we already released. New era is here, let's define it!</p>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default History;
