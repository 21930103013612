import React from 'react';
import PageTitle from '../components/pagetitle';
import Project from '../features/project/nftitem';


function NftItem(props) {
    return (
        <div className='page-nft'>
            <PageTitle title='NFT ITEM’S' />

            <Project />
        </div>
    );
}

export default NftItem;