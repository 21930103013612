const dataRoadmap = [
    {
        id: 1,
        time: 'Q2 2022',
        list: [
            {
                text: 'Release website v1.0.0'
            },
            {
                text: 'Start MINT of DinoCPP'
            },
            {
                text: 'Release Merch'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'Q3 2022',
        list: [
            {
                text: 'Grow DinoCPP community'
            },
            {
                text: 'Social media setup'
            },
            {
                text: 'DCPP crypto coin implementation'
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'Q4 2022',
        list: [
            {
                text: 'Release DCPP crypto coin'
            },
            {
                text: 'Link DCPP coin with DinoCPP NFT'
            },
            {
                text: 'Imoprove Merch Collection'
            },
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Q1 2023',
        list: [
            {
                text: 'Release website v 2.0.0'
            },
            {
                text: 'DinoCPP BANK implementation'
            },
            {
                text: 'Release DinoCPP BANK'
            },
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: 'Q2 2023',
        list: [
            {
                text: 'Start staking of DinoCPP NFT'
            },
            {
                text: 'Integration of Embedded School with tech courses'
            },
            {
                text: 'Free access to Embedded School courses by Holding NFT'
            },
        ],
        positon: 'left'
    },
    {
        id: 6,
        time: 'Q3 2023',
        list: [
            {
                text: 'Release DinoCPP newspaper'
            },
            {
                text: 'Intro into Metaverse & Gamefy'
            },
            {
                text: 'Designing of Gamefy strategy'
            },
        ],
        positon: 'right'
    },
    {
        id: 7,
        time: 'Q4 2023',
        list: [
            {
                text: 'DinoCPP LAND in Meta'
            },
            {
                text: 'Mint Land parcels for Meta Gamefy'
            },
            {
                text: 'Grow Gamefy community'
            },
        ],
        positon: 'left'
    },
    {
        id: 8,
        time: 'Q3 2024',
        list: [
            {
                text: 'Mint weapons for DinoCPP Models'
            },
            {
                text: 'Beta version release of DinoCPP VR Game'
            },
            {
                text: 'Buy weapons with DCPP coin'
            },
        ],
        positon: 'right'
    },
    {
        id: 9,
        time: 'Q4 2024',
        list: [
            {
                text: 'Work around DinoCPP Comics'
            },
            {
                text: 'Release v1 tech comics'
            },
        ],
        positon: 'left'
    },
]

export default dataRoadmap;