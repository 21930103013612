const dataAbout = [
    {
        id: 1,
        title: 'Unique, 1/1 generated',
        desc: 'Each DinoCPP is unique and algorithmically generated from over 150 possible hand drawn layers. All DinoCPP are dope, but some are legends and even rarer than others.',
        class: 'active'
    },
    {
        id: 2,
        title: 'Linked with DCPP coin',
        desc: 'Each DinoCPP is linked with own crypto coin DCPP that will increase your earning in Staking of DiniCPP. ',
    },
    {
        id: 3,
        title: 'Tech Scool and Bank integration',
        desc: 'By Holding DinoCPP NFT you have access to tech courses for free and DinoCPP Bank where you can stak your NFT and earn DCPP coins.',
    },
]

export default dataAbout;