import { useState, useEffect } from "react";
import config from "../config";

export const GET_COLLECTION_DATA = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(`https://api.opensea.io/api/v1/assets?asset_contract_address=${config.contract_address}&format=json`)
            .then((res) => res.json())
            .then((data) => setData(data['assets']));
    }, []);

    return data;
};
