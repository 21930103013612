import React , {useState} from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
// import Button from '../../components/button';
import AboutItem from './about-item';
// import {Link} from "react-router-dom";

About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subtitle : 'About us',
            title: 'What is dinocpp?',
            desc : '245 million years ago on earth planet, Tyrannosaurus invented C++ programming language and beginning Smart Contracts for Stegosaurus who liked minting NFTs with Pteranodon. Since then on W3 space there is a joke that C++ is for dinosaurs and C++ developers are dinosaurs!DinoCPP NFT is the key for the Metaverse School Platform. We are building an metaverse school where people could learn tech, design, programming...etc courses in metaverse universe. The NFT holders will have free access to the all courses and will have facilities to become a teacher, trainer, mentor or coach. Firs course that will take place in DinoCPP Meta School will be C++ programming language.'
        }
    )

    return (
        <section className="tf-section tf-about">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-12">
                        <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title st2">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p>{dataBlock.desc}</p>
                            <a href="https://opensea.io/collection/dinocpp" target="_blank" rel="noreferrer" className="tf-button btn-effect">
                                <span className="boder-fade"></span>
                                <span className="effect">Buy one NFT</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            {
                                data.map(item => (
                                    <AboutItem key={item.id} item={item} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;