const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'DOPE NFT',
        text : '2584 Series of unique Dinosaurs arithmetically generated from 150+ hand drawn layers that will help you learn tech courses and read tech comics.',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'DCPP Crypto Coin',
        text : 'Brand new coin linked with NFT will give you possibility of buying DinoCPP services, like comics, tech news, tech courses, metaverse game units.'
    },
    {
        id: 3,
        numb: '03',
        title: "DinoCPP Bank - Staking",
        text : 'Provide you the possibility of earning DCPP coins by staking your NFT into virtual bank. The more you stake the more you earn.'
    },
    {
        id: 4,
        numb: '04',
        title: "Metaverse Gamefy",
        text : 'Integration in Metaverse and Gamefy. VR unreal engine game will be done according to dinoCPP comics scenario.'
    },
]
export default dataWork;