import bgImg1 from '../images/background/bg1.gif';
import bgImg2 from '../images/background/bg2.jpg';
import bgImg3 from '../images/background/bg3.png';
import bgImg4 from '../images/background/bg4.png';
import img1 from '../images/common/c.png';
import img2 from '../images/common/dinoco22b.png';

const dataSlider = [
    {
        id: 1,
        title: 'DINOCPP NFT COLLECTION for dinosaurs in IT',
        desc : 'Limited series of unique Dinosaurs that leads the Technology, living on the Ethereum Blockchain. Each DinoCPP is algorithmically generated for your personality.',
        bgImg: bgImg1,
        img : img1,
        classAction: 'two'
    },
    {
        id: 2,
        title: '2584 unique collection algorithmically generated',
        desc : 'Each DinoCPP is unique and algorithmically generated from over 150 possible hand drawn layers. All DinoCPP are dope, but some are legends and even rarer than others.',
        bgImg: bgImg2,
        img : img2,
        classAction: 'two'
    },
    {
        id: 3,
        title: 'DCPP Crypto Coin integration linked with NFT.',
        desc : 'Trade, Earn, Staking. Use DinoCPP NFTs in Staking and earn DCPP Coins. The more you staked the more you earn.',
        bgImg: bgImg3,
        classAction: 'two'
    },
    {
        id: 4,
        title: 'DINOCPP Integration in Metaverse and Gamefy',
        desc : 'DinoCPP LAND - each dino will have access to VR Game where every win means earning of DCPP coins.',
        bgImg: bgImg4,
        classAction: 'two'
    },
    {
        id: 5,
        title: 'DINOCPP BANK - Earn DCPP coins by staking NFTs',
        desc : 'Put your DinoCPP work for you. In staking you earn DCPP coinss',
        bgImg: bgImg2,
        classAction: 'two'
    },

]

export default dataSlider;
