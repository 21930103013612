import img1 from '../images/team/profile1.jpg';
import img2 from '../images/team/profile2.jpg';
import img3 from '../images/team/profile3.jpg';
import img4 from '../images/team/profile4.jpg';
import img5 from '../images/common/team10.png';
import img6 from '../images/common/team11.png';

const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Gheorghe Ghirjev',
        position: 'CEO & Blockchain Developer. That guy who had the idea.',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: 'https://www.facebook.com/girjau.gheorghe'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin-in',
                link: 'https://www.linkedin.com/in/gheorghe-ghirjev-172284b9/'
            },
        ]
    },
    {
        id: 2,
        img: img2,
        name: 'Doina Ilies',
        position: 'ART Director. Someone stop her drawing on walls',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin-in',
                link: 'https://www.linkedin.com/in/doina-ilies-a91258148/'
            },
        ]
    },
    {
        id: 3,
        img: img3,
        name: 'Viorel Popa',
        position: 'Backend Developer. We never saw him, we\'re on Front',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin-in',
                link: 'https://www.linkedin.com/in/viorelmd/'
            },
        ]
    },
    {
        id: 4,
        img: img4,
        name: 'Development Team ',
        position: 'C++/BlockChain Developers. FindBug/FuckBug team. Scrum Agyle Haters',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin-in',
                link: '#'
            },
        ]
    },
    {
        id: 5,
        img: img5,
        name: 'Igor Railean',
        position: 'C++ Developer. Never late to the meetings, write Smart Contracts in assembler.',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin-in',
                link: '#'
            },
        ]
    },
    {
        id: 6,
        img: img6,
        name: 'Natalia Cartera ',
        position: 'Wife of firt\'s guy. Added her here because she is not jealous - she just asks.',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-linkedin-in',
                link: '#'
            },
        ]
    },
]

export default dataTeam;
